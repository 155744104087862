import React from "react"
import { Link } from "gatsby"
import GitHub from "./github"
import Twitter from "./twitter"
import LinkedIn from "./linkedin"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  let header

  if (location.pathname === rootPath) {
    header = (
      <div className="name" style={{marginTop: "4.828rem", marginBottom: "5rem", textAlign: "center"}}>
        <h1 className="title">
          E*PEERY<br/>NEW YORK
        </h1>
        <h3><span>PERSONAL BLOG</span></h3>
        <div className="socials">
          <GitHub path="https://github.com/epeery"/>
          <Twitter path="https://twitter.com/egpeery"/>
          <LinkedIn path="https://linkedin.com/in/epeery"/>
        </div>
      </div>
    )
  } else {
    header = (
      <div className="name sticky">
      <h2>
        <Link to={`/`}>
          {title}
        </Link>
      </h2>
      </div>
    )
  }
  return (
    <div>
      {header}
      <main>{children}</main>
    </div>
  )
}

export default Layout
